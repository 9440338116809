/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* GLOBAL FONTS */

@font-face {
    font-family: 'Muli-ExtraBold';
    src: url('./assets/fonts/muli/static/Muli-ExtraBold.ttf')
}

@font-face {
    font-family: 'Muli-Bold';
    src: url('./assets/fonts/muli/static/Muli-Bold.ttf')
}

@font-face {
    font-family: 'Muli-Regular';
    src: url('./assets/fonts/muli/static/Muli-Regular.ttf')
}

@font-face {
    font-family: 'Muli-Black';
    src: url('./assets/fonts/muli/static/Muli-Black.ttf')
}

$gray: #6C7783;
$primary: #011957;
$secondary: #209CFE;
$danger: #ff0000;
$chip-pending: #6C7783;
$no-active: #DADDE0;
$border: 2px solid rgb(108,
119,
131,
.1);
ion-content {
    --background: rgba(108, 119, 131, .25);
}

// ion-header {
//     background-color: $primary;
//     height: 100px;
//     img {
//         height: 100%;
//     }
// }

ion-searchbar .searchbar-input {
    font-family: Muli-Regular !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}


/****************FONTS****************/

.muliextrabold {
    font-family: Muli-ExtraBold;
}

.mulibold {
    font-family: Muli-Bold;
}

.muliregular {
    font-family: Muli-Regular;
}


/****************FONTS***************/


/**************COLORS****************/

.primary {
    color: $primary;
    font-size: 12px;
}

.primary-color {
    color: $primary;
}

.secondary-color {
    color: $secondary;
}


/**************COLORS****************/

.swal2-popup {
    font-family: Muli-Regular !important;
}

.swal2-container {
    height: 100vh !important
}

h3 {
    color: $primary;
}

.text-color {
    color: $secondary;
}

.item-align-center {
    text-align: center;
}

ion-card-header>ion-item {
    --border-color: #209CFE;
}

.item-goal {
    border-bottom: 1px solid $secondary;
}

.div-goal-text {
    margin-bottom: 15px;
    padding: 5px 0;
}

.wgoal-text {
    color: $gray;
    font-size: 16px;
    margin-bottom: 0;
}

.main-segment {
    font-family: Muli-Bold;
    font-size: 12px;
    font-weight: bold;
    border-bottom: $border;
    ion-segment-button {
        --indicator-color: #209CFE;
        letter-spacing: 0;
        min-width: 60px;
        text-transform: none;
    }
}

.no-jobs {
    color: $primary;
    font-family: Muli-Bold;
    margin-top: 50px;
    text-align: center;
}

.jobs {
    h3 {
        font-size: 20px;
    }
}

.date {
    font-size: 12px !important;
}

.priority {
    color: $gray;
    font-size: 12px !important;
}

.address {
    color: $secondary;
    font-size: 12px !important;
}

.door_code {
    color: $gray;
    font-size: 12px !important;
}

.ul-jobs {
    display: block;
    font-family: Muli-Regular;
    margin: 0;
    padding: 0;
    .li-jobs {
        border-bottom: $border;
        list-style: none;
        padding: 5px 0;
        text-align: left;
        ion-row {
            ion-col {
                p {
                    font-size: 12px;
                }
            }
        }
        .slide-jobs {
            height: 0;
            margin-top: 16px;
            overflow: hidden;
            ion-card {
                box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 5px;
                font-family: Muli-Regular !important;
                font-size: 12px !important;
                margin: 24px 4px 35px 4px;
            }
        }
    }
    button {
        border: none;
        background: none;
    }
    ion-card {
        padding: 16px;
        width: 100%;
    }
}

.expanded {
    border-top: 2px solid $secondary;
    height: 100% !important;
}

.notes {
    text-align: left;
}

.row-chips {
    margin-bottom: 12px;
}

.col-confirm-chip {
    text-align: right;
    ion-chip {
        margin-right: 0;
    }
}

ion-chip {
    font-family: Muli-Black;
    font-size: 9px;
    font-weight: bold;
    margin: 0 4px;
    padding: 1px 10px;
    text-transform: uppercase;
}

.ul-details {
    display: block;
    margin: 0;
    padding: 0;
    li {
        border-bottom: $border;
        list-style: none;
        margin: 5px 0;
        padding: 5px;
        text-align: left;
        p {
            font-size: 12px !important;
            margin: 5px 0;
        }
    }
}

.ion-chip-work {
    border: 1px solid $secondary;
    background: none;
    color: $secondary;
    height: 20px;
    margin-left: 0;
}

.ion-chip-trip {
    border: 1px solid $primary;
    background: none;
    color: $primary;
    height: 20px;
    margin-left: 0;
}

.ion-chip-main {
    background: $secondary;
    color: #fff;
    height: 20px;
    margin-left: 0;
}

.ion-chip-danger {
    background: $danger;
    color: #fff;
    height: 20px;
    width: max-content;
    margin-left: 0;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.detail-arrow {
    margin: auto;
}

.col-border {
    p {
        border-bottom: 1px solid $secondary;
    }
}

.row-input {
    border-bottom: $border;
    font-family: Muli-Regular;
    font-size: 12px;
    ion-col {
        ion-checkbox {
            margin-top: 5px;
        }
    }
}

.ul-images {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    li {
        border-bottom: 0;
        border-top: $border;
        list-style: none;
        padding: 5px;
        text-align: left;
        ion-col {
            ion-img {
                width: 40px;
                margin: auto;
            }
        }
    }
}

.card-photos {
    ion-row {
        ion-col {
            padding: 5px 0;
            font-family: Muli-Regular;
            .btn-small {
                font-family: Muli-Bold !important;
                font-size: 12px !important;
            }
            .btn-block {
                font-family: Muli-Bold !important;
                ion-icon {
                    margin-right: 10px;
                }
            }
            .primary {
                font-size: 14px !important;
            }
        }
    }
}


/******* Diseño del input number************/

.col-quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.quantity {
    display: inline-block !important;
    height: 30px;
    position: relative;
}

.quantity input {
    border: $border;
    border-left: none;
    border-radius: 0 8px 8px 0;
    display: flex !important;
    float: left;
    height: 100%;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    text-align: center;
}

.quantity input:focus {
    outline: 0;
}

.inspection {
    width: 50%;
}

.input-nav {
    display: block !important;
    float: left;
    height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 30px;
}

.qbutton {
    background-color: $secondary !important;
    border: 1px solid $secondary;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    line-height: 1.5;
    margin: 0;
    position: relative;
    padding: 0;
    text-align: center;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.qbutton.q-up {
    border-bottom: 1px solid rgb(255, 255, 255, 0.08);
    border-radius: 4px 0 0 0;
    height: 50%;
    left: 0;
    top: 0;
    position: absolute;
}

.qbutton.q-down {
    bottom: 0;
    border-radius: 0 0 0 4px;
    height: 50%;
    left: 0;
    position: absolute;
}

.unit_measurement {
    margin-left: 5px;
}


/******* /Diseño del input number************/


/****** Mapa *****/

#map {
    height: 300px;
    width: 100%;
}


/****** Mapa *****/


/********** COMPLETED JOBS ************/

.row-filter {
    margin: 0 0 10px 0;
    ion-col {
        padding: 5px 0;
        ion-datetime {
            align-items: center;
            border: $border !important;
            border-radius: 0 4px 4px 0;
            font-family: Muli-Regular;
            height: 30px;
            text-align: left;
            padding: 5px 5px;
            width: 70%;
        }
    }
    ion-select {
        font-family: Muli-Regular;
    }
    .title-payment {
        margin: auto;
        text-align: left;
        ion-label {
            color: $primary;
            font-family: Muli-Bold;
            font-size: 14px;
        }
    }
    .menu-payment {
        font-family: Muli-Bold;
        font-size: 12px !important;
        font-weight: bold;
        ion-segment-button {
            --indicator-color: #209CFE;
            letter-spacing: 0;
            text-transform: none;
            min-width: 50px !important;
            min-height: 30px !important;
            ion-label {
                margin: 0;
            }
        }
    }
    .col-payment {
        margin-bottom: 10px;
    }
}


/****************Diseño del datetime ******/

.date-nav {
    display: flex !important;
    float: left;
    height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 20%;
}

.datebutton {
    background-color: $secondary !important;
    border-radius: 4px 0 0 4px;
    color: #fff;
    height: 100%;
    line-height: 1.5;
    margin: 0;
    position: relative;
    padding: 0;
    text-align: center;
    width: 100%;
}

.end-date {
    width: 90% !important;
}

.hk-calendar{
    border: $border !important;
    border: 2px solid rgba(108, 119, 131, 0.1) !important;
    border-radius: 0 4px 4px 0;
    font-family: Muli-Regular;
    height: 30px;
    text-align: center;
    width: 90%;
    ion-label {
        font-size: 10px;
    }
}


/**************** /Diseño del datetime ******/

.selectPayment {
    border-radius: 4px;
    display: block;
    font-family: Muli-regular;
    font-size: 12px;
    padding: 5px;
    width: 100%;
}

.searchcustom {
    --background: none;
    --box-shadow: none;
    border: $border;
    border-radius: 4px;
    padding: 0;
    text-align: left;
    height: 30px;
}

.ul-completed {
    display: block;
    margin: 0;
    padding: 0;
    text-align: center;
    .li-completed {
        border-top: $border;
        font-family: Muli-Regular;
        list-style: none;
        padding: 5px 0;
        .detail {
            height: 0;
            overflow: hidden;
            width: 100%;
        }
        p {
            font-size: 12px !important;
        }
    }
}

.expanded-pay {
    border-top: 2px solid $secondary;
    height: 100% !important;
}


/***** PAYMENT HISTORY ******/

.section-history {
    .ul-details-completed {
        display: block;
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            margin-bottom: 10px;
            text-align: left;
            ion-col {
                padding: 0 5px;
            }
        }
    }
}

.totalpayment {
    border-bottom: $border;
    border-top: $border;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    h2 {
        color: $primary;
        font-family: Muli-ExtraBold;
        font-weight: bold;
    }
    h1 {
        color: $primary;
        display: flex;
        float: left;
        font-family: Muli-ExtraBold;
        font-weight: bold;
        font-size: 20px;
    }
    .signo-dollar {
        margin: auto;
        color: $secondary;
        font-size: 10px;
    }
    .dollar {
        color: $secondary;
        font-size: 10px;
    }
}

.comment-payment {
    text-align: left;
    h4 {
        color: $primary;
        font-family: Muli-Bold;
        font-size: 12px;
    }
}


/***** /PAYMENT HISTORY ******/


/*********PAYMENT REQUEST **************/

.section-request {
    .ul-payment-request {
        display: block;
        margin: 0;
        padding: 0;
        li {
            border-top: $border;
            font-family: Muli-Regular;
            list-style: none;
            margin-bottom: 10px;
            padding-top: 10px;
            text-align: left;
            ion-col {
                padding: 0 5px;
            }
        }
        .group-detail {
            p {
                font-size: 12px !important;
            }
        }
        .col-check {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            padding-right: 0 !important;
        }
    }
    .totalpayment {
        border-bottom: none;
    }
    .chip-balance {
        background-color: red;
        color: #fFf;
        height: 15px;
        vertical-align: top;
    }
    .chip-adjusted {
        background-color: #6c7783;
        color: #fFf;
        height: 15px;
    }
    .comment-payment {
        ion-textarea {
            --padding-start: 5px;
            --padding-top: 5px;
            border: $border;
            font-family: Muli-Regular;
            font-size: 12px;
        }
    }
    .btn-small {
        font-family: Muli-Bold;
        font-size: 12px;
    }
}


/********* /PAYMENT REQUEST **************/


/************PENDING PAYMENT *************/

.section-pending {
    .ul-payment-request {
        display: block;
        margin: 0;
        padding: 0;
        li {
            border-top: $border;
            font-family: Muli-Regular;
            list-style: none;
            margin-bottom: 10px;
            padding-top: 10px;
            text-align: left;
            ion-col {
                padding: 0 5px;
            }
        }
        .group-detail {
            display: flex;
            p {
                font-size: 12px !important;
                margin-right: 10px;
            }
        }
        .col-check {
            align-items: center;
            display: flex;
            justify-content: flex-end;
            padding-right: 0 !important;
        }
    }
    .chip-pending {
        color: $chip-pending;
        font-family: Muli-Black;
        height: 15px;
    }
    .row-comment {
        margin-top: 10px;
    }
}


/************ /PENDING PAYMENT *************/


/********** /COMPLETED JOBS ************/


/********* RECURRING JOBS ***************/

.ul-recurring {
    display: block;
    font-family: Muli-Regular;
    margin: 0;
    padding: 0;
    .li-recurring {
        font-size: 12px;
        list-style: none;
        padding: 5px 0;
        text-align: left;
        .detail-arrow {
            float: right;
        }
        .detail-recurring {
            height: 0;
            margin-top: 16px;
            overflow: hidden;
        }
        h1 {
            color: $primary;
            font-family: Muli-Bold;
            font-size: 14px;
        }
        .a-address {
            color: $gray !important;
        }
        ion-col {
            padding: 5px 0;
        }
        ion-button {
            font-family: Muli-Bold;
            font-size: 12px;
            margin-bottom: 15px;
            text-transform: none;
        }
        .time-history {
            color: $primary;
            font-family: Muli-ExtraBold;
            font-size: 14px;
            font-weight: 900;
        }
        .door-block {
            display: block;
        }
        .badge {
            --padding-top: 8px;
            --padding-end: 8px;
            --padding-bottom: 8px;
            --padding-start: 8px;
            border-radius: 100%;
            font-family: Muli-Regular;
            font-size: 9px;
            margin-left: 2px;
        }
        .no-active {
            background-color: $no-active;
            color: $gray;
        }
        .col-time {
            padding-top: 0;
        }
        .col-detail-re {
            padding-bottom: 0;
        }
        .col-time-history {
            border-bottom: $border;
        }
    }
}

.expandedRecurring {
    height: 100% !important;
}

.row-table-recurring {
    height: 0;
    margin-top: 10px;
    overflow: hidden;
    ion-datetime {
        align-items: center;
        border: $border !important;
        border-radius: 0 4px 4px 0;
        font-family: Muli-Regular;
        height: 30px;
        text-align: left;
        padding: 5px 5px;
        width: 70%;
    }
    ion-grid {
        padding: 5px 0;
    }
    .header-row {
        border-bottom: 2px solid $secondary;
        border-top: 2px solid $secondary;
        color: $primary;
        font-family: Muli-Black;
        font-size: 12px;
        width: 100%;
    }
    .clock {
        margin-right: 10px;
    }
    .clock-rotate {
        transform: rotate(90deg);
    }
}

.already-clock {
    --background: rgba(108, 119, 131, .25);
    --color: #5b5b5c;
}

.btn-clock-out {
    --background: red;
    --color: #fff;
}

.expandedTable {
    height: auto !important;
    border-bottom: 2px solid $secondary;
}

.swal2-textarea {
    font-family: Muli-Regular !important;
    font-size: 12px !important;
}

#fileInput{
    position: absolute;
    opacity: 0;
  }
.uploadButton{
    background-color: #209CFE;
    border: 1px solid #ccc;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Muli-Bold';
    font-size: 14px;
    height: 100%;
    letter-spacing: 0.5px;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;
}

input[type="file"] {
    display: none;
}

/**********REPORT PROBLEMS ***************/
.type-report {
    .picker-opt {
        font-family: 'Muli-Regular';
        font-size: 16px;
    }
    .picker-opt-selected {
        font-family: 'Muli-ExtraBold';
    }
    .btn-cancel {
        font-family: 'Muli-Bold';
        color: #ff0000 !important;
    }
    .btn-send {
        font-family: 'Muli-Bold';
        color: #209CFE !important;
    }
}



/********* /RECURRING JOBS ***************/

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

